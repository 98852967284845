<template>
<body class="layout-light side-menu overlayScroll">
    <main class="main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="shop-breadcrumb">
                    </div>
                    <div class="global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                        <!-- <div class="row justify-content-center"> -->
                            <!-- <div class="col-xl-7 col-lg-10"> -->
                                <div class="mx-sm-30 mx-20 ">
                                    <!-- Start: card -->
                                    <div class="card add-product p-sm-30 p-20 mb-30" style="width:200%">
                                        <div class="card-body p-0">
                                            <div class="card-header">
                                                <h4 class="fw-500">Bill Address</h4>
                                            </div>
                                            <!-- Start: card body -->
                                            <div class="add-product__body px-sm-40 px-20">
                                                <!-- Start: form -->
                                                <form>
                                                    <!-- form group -->
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Name</h6><br>
                                                        <BaseInput type="text" v-model="$v.Name.$model" :validate="$v.Name" :feedback="false" toggleMask placeholder="Enter Your Name" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Name.required !== 'undefined' &&$v.Name.required=='' &&$v.Name.$error">
                                                            Name is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Mobile No</h6><br>
                                                        <BaseInput type="number" v-model="$v.Mobile.$model" :validate="$v.Mobile" :feedback="false" toggleMask placeholder="Enter Your Mobile No" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Mobile.required !== 'undefined' &&$v.Mobile.required=='' &&$v.Mobile.$error">
                                                            Mobile No is required
                                                        </div>
                                                        <div class="validation-div mb-2" v-if="
                                      !$v.Mobile.min 
                                    ">
                                                            Password length must be atleast characters
                                                            {{ $v.Mobile.$params.min.min }}
                                                            characters.
                                                        </div>
                                                        <div class="validation-div mb-2" v-if="
                                      !$v.Mobile.max 
                                    ">
                                                            Password length must be atleast characters
                                                            {{ $v.Mobile.$params.max.max }}
                                                            characters.
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Alternative No</h6><br>
                                                        <BaseInput type="number" v-model="$v.Alternative.$model" :validate="$v.Alternative" :feedback="false" toggleMask placeholder="Enter Your Alternative No" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Alternative.required !== 'undefined' &&$v.Alternative.required=='' &&$v.Alternative.$error">
                                                            Alternative No is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Address Line1</h6><br>
                                                        <BaseInput type="text" v-model="$v.Address.$model" :validate="$v.Address" :feedback="false" toggleMask placeholder="Enter Your Address Line1" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Address.required !== 'undefined' &&$v.Address.required=='' &&$v.Address.$error">
                                                            Address Line1 is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Address Line2</h6><br>
                                                        <BaseInput type="text" v-model="$v.Address2.$model" :validate="$v.Address2" :feedback="false" toggleMask placeholder="Enter Your Address Line2" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Address2.required !== 'undefined' &&$v.Address2.required=='' &&$v.Address2.$error">
                                                            Address Line2 is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>City</h6><br>
                                                        <BaseInput type="text" v-model="$v.City.$model" :validate="$v.City" :feedback="false" toggleMask placeholder="Enter Your City" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.City.required !== 'undefined' &&$v.City.required=='' &&$v.City.$error">
                                                            City is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>State</h6><br>
                                                        <BaseInput type="text" v-model="$v.State.$model" :validate="$v.State" :feedback="false" toggleMask placeholder="Enter Your State" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.State.required !== 'undefined' &&$v.State.required=='' &&$v.State.$error">
                                                            State is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Zip Code</h6><br>
                                                        <BaseInput type="text" v-model="$v.Zip.$model" :validate="$v.Zip" :feedback="false" toggleMask placeholder="Enter Your Zip Code" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Zip.required !== 'undefined' &&$v.Zip.required=='' &&$v.Zip.$error">
                                                            ZipCode is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Place (Office or Home)</h6><br>
                                                        <BaseInput type="text" v-model="$v.Place.$model" :validate="$v.Place" :feedback="false" toggleMask placeholder="Enter Your Place" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Place.required !== 'undefined' &&$v.Place.required=='' &&$v.Place.$error">
                                                            Place is required
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-group add-product-btn d-flex justify-content-end mt-40">
                                        <BaseButton label="Save" />
                                    </div>
                                </div>
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </main>
</body>
</template>

<script>
import {
    required,
    maxLength,
    minLength
} from "vuelidate/lib/validators";
export default {
    data() {
        return {
            Name: '',
            Mobile: '',
            Alternative: '',
            Address: '',
            Address2: '',
            City: '',
            State: '',
            Zip: '',
            Place: ''
        }
    },
    validations() {
        return {
            Name: {
                required
            },
            Mobile: {
                required,
                min: minLength(10),
                max: maxLength(10)
            },
            Alternative: {
                required
            },
            Address: {
                required
            },
            Address2: {
                required
            },
            City: {
                required
            },
            State: {
                required
            },
            Zip:{
                required
            },
            Place:{
                required
            }
        }
    }
}
</script>
