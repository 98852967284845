<template>
  
    <FileUpload
      :id="id"
      :name="name"
      :mode="mode"
      :class="[
        { 'p-invalid': typeof validate !== 'undefined' && validate.$error },
        inputClass,
      ]"
      :maxFileSize="maxFileSize"
      :accept="accept"
      :invalidFileSizeMessage="invalidFileSizeMessage"
      :invalidFileTypeMessage="invalidFileTypeMessage"
      :multiple="multiple"
      :fileLimit="fileLimit"
      :invalidFileLimitMessage="invalidFileLimitMessage"
      :previewWidth="previewWidth"
      :customUpload="customUpload"
      :chooseLabel="chooseLabel"
      :showUploadButton="showUploadButton"
      :showCancelButton="showCancelButton"
      @uploader="$emit('uploader', $event.files)"
      @upload="$emit('upload', $event.files)"
      @select="$emit('select', $event.files)"
      @error="$emit('error', $event.files)"
      ref="imageUpload"
      :disabled="disabled"
    >
      <template #empty>
        <slot name="preview"></slot>
      </template>
    </FileUpload>
    
</template>

<script>
import FileUpload from "primevue/fileupload";

export default {
  components: {
    FileUpload,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "image",
    },
    label: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "advanced",
    },
    labelShown: {
      type: Boolean,
      default: true,
    },
    inputClass: {
      type: String,
      required: false,
    },
    chooseLabel: {
      type: String,
      default: "Browse",
    },
    previewWidth: {
      type: Number,
      default: 50,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    customUpload: {
      type: Boolean,
      default: true,
    },
    fileLimit: {
      type: Number,
      default: 1,
    },
    invalidFileTypeMessage: {
      type: String,
    },
    maxFileSize: {
      type: Number,
    },
    invalidFileSizeMessage: {
      type: String,
    },
    invalidFileLimitMessage: {
      type: String,
    },
    showUploadButton: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Object,
      default: () => {},
    },
    submitted: {
      type: Boolean,
      default: false,
    },
    hasGroup: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    const fileUpload = document.querySelector(".p-fileupload-choose");
    fileUpload.addEventListener("click", this.browseUpload);
  },
  methods: {
    browseUpload() {
      const fileUpload = document.querySelector(".p-fileupload-choose");
      if (fileUpload?.firstChild?.disabled) {
        this.$toastr.warn(
          "Please remove the existing file to upload the new file"
        );
      }
    },
  },
};
</script>
