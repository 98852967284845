<!-- <template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  
}
</script>

<style>
@import "./assets/theme_assets/sass/style.scss";

</style> -->


<template>
  <component :is="layout">
  <router-view />
    <!-- <slot /> -->
  </component>
</template>

<script>
import AppLayoutDefault from './components/layout/AuthLayout.vue';
export default {
  name: "AppLayout",
  data: () => ({
    layout: AppLayoutDefault
  }),
  watch: {
    $route: {
      immediate: true,
      async handler(route) {try {
          const component = await import(`@/components/layout/${route.meta.layout}.vue`)
          this.layout = component?.default || AppLayoutDefault
        } catch (e) {
          this.layout = AppLayoutDefault
        }
      }
    }
  }
}
</script>

<style>

</style>



