<template>
<body class="layout-light side-menu overlayScroll">
    <main class="main-content">
        <!-- <div class="padding"> -->
            <div class="container-fluid editproduct">
                <!-- <div class="row"> -->
                <!-- <div class="col-lg-12 "> -->
                <div class="shop-breadcrumb ">

                    <!-- <div class="breadcrumb-main"> -->
                        <!-- <h4 class="text-capitalize breadcrumb-title">Add Product</h4>&emsp; -->
                    <!-- </div> -->
                </div>
                <div class="global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                    <div class="row justify-content-center">
                        <!-- <div class="col-xl-7 col-lg-10"> -->
                        <div class="mx-sm-30 mx-10 padding">
                            <!-- Start: card -->
                            <div class="card add-product p-sm-30 p-20 mb-30" style="width:200% !important; ">
                                <div class="card-body p-0">
                                    <div class="card-header padding">
                                        <h4 class="fw-500">About Product</h4>
                                    </div>
                                    <!-- Start: card body -->
                                    <div class="add-product__body px-sm-40 px-20">
                                        <!-- Start: form -->
                                        <form>
                                            <!-- form group -->
                                            <div class="form-group p-fluid">&emsp;
                                                <h6>Product name</h6><br>
                                                <BaseInput type="text" v-model="$v.Productname.$model" :validate="$v.Productname" />
                                                <div class="validation-div mb-2" v-if="typeof $v.Productname.required !== 'undefined' &&$v.Productname.required=='' &&$v.Productname.$error">
                                                    Product name is required
                                                </div>
                                            </div>

                                            <!-- form group 1 -->
                                            <div class="form-group p-fluid">&emsp;
                                                <h6>Description</h6><br>
                                                <BaseInput type="text" v-model="$v.Description.$model" :validate="$v.Description" />
                                                <div class="validation-div mb-2" v-if="typeof $v.Description.required !== 'undefined' &&$v.Description.required=='' &&$v.Description.$error">
                                                    Description is required
                                                </div>
                                            </div>

                                            <!-- form group 2 -->
                                            <div class="form-group">&emsp;
                                                <div class="countryOption">
                                                    <h6>
                                                        Product Image
                                                    </h6><br>
                                                    <BaseImageUpload mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />
                                                </div>
                                            </div>

                                            <!-- form group 3 -->
                                            <!-- <div class="form-group quantity-appearance"> -->
                                            <div class="form-group p-fluid">&emsp;
                                                <h6>Quantity</h6><br>
                                                <BaseInput type="number" id="withoutgrouping" v-model="$v.Quantity.$model" :validate="$v.Quantity" mode="decimal" :useGrouping="false" />
                                                <div class="validation-div mb-2" v-if="typeof $v.Quantity.required !== 'undefined' &&$v.Quantity.required=='' &&$v.Quantity.$error">
                                                    Quantity is required
                                                </div>
                                            </div>
                                            <!-- </div> -->

                                            <!-- form group 4 -->
                                            <!-- <div class="form-group quantity-appearance"> -->
                                            <h6>Price</h6>&emsp;
                                            <!-- <div class="grid p-fluid"> -->
                                                <!-- <div class="col-12 md:col-4"> -->
                                                    <div class="p-inputgroup">
                                                        <!-- <span class="p-inputgroup-addon">
                                                            <i class="pi pi-dollar"></i>
                                                        </span> -->
                                                        <BaseInput type="number" v-model="$v.Price.$model" :validate="$v.Price" />
                                                    </div>
                                                    <div class="validation-div mb-2" v-if="typeof $v.Price.required !== 'undefined' &&$v.Price.required=='' &&$v.Price.$error">
                                                        Price is required
                                                    </div>
                                                <!-- </div> -->
                                            <!-- </div> -->

                                        </form>
                                        <!-- End: form -->
                                    </div>
                                    <!-- End: card body -->
                                </div>
                            </div>

                            <!-- Start: button group -->
                            <div class="button-group add-product-btn d-flex justify-content-end mt-40">
                                <BaseButton label="cancel" />&emsp;
                                <BaseButton label="Save Product" />
                            </div>
                            <!-- End: button group -->
                        </div>
                        <!-- </div> -->
                        <!-- ends: col-lg-8 -->
                    </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>
        <!-- </div> -->     
    </main>
</body>
<!-- </div> -->
</template>

<script>
import {
    required
} from 'vuelidate/lib/validators';
export default {
    data() {
        return {
            Productname: '',
            Description: '',
            Quantity: '',
            Price: ''
        }
    },
    validations() {
        return {
            Productname: {
                required
            },
            Description: {
                required
            },
            Quantity: {
                required
            },
            Price: {
                required
            }
        }
    }
}
</script>

<style>
.editproduct{
    width: 100%;
}
</style>
