<template>
<div class="holder">
    <div class="container">
    </div>
    <div class="filter-row">
        <div class="row">
            <p>35 item(s)</p>
            <!-- <div class="select-wrap d-none d-md-flex"> -->
            <!-- <div class="select-label">SORT:</div> -->
            <h6 class="select-label">SORT :</h6>
            <!-- <div class="select-wrapper select-wrapper-xxs"> -->
            <!-- <select class="form-control input-sm"> -->
            <BaseDropDown style="max-width:118px;" v-model="selectedCity1" :options="cities" optionLabel="name" placeholder="" />
            <!-- </select> -->
            <!-- </div> -->
            <!-- </div> -->
            <h6>VIEW :</h6>
            <BaseDropDown v-model="selectedCity2" :options="city" optionLabel="names" placeholder="" />
            <!-- <div class="select-wrap d-none d-md-flex">
                <div class="select-label">VIEW:</div>
                <div class="select-wrapper select-wrapper-xxs">
                    <select class="form-control input-sm">
                        <option value="featured">12</option>
                        <option value="rating">36</option>
                        <option value="price">100</option>
                    </select>
                </div>
            </div> -->
            <div class="viewmode-wrap">
                <div class="view-mode">
                    <span class="js-horview d-none d-lg-inline-flex"><i class="pi pi-list"></i></span>&emsp;
                    <span class="js-gridview"><i class="pi pi-list"></i></span>&emsp;
                    <span class="js-listview"><i class="pi pi-list"></i></span><br>
                </div>
            </div>
        </div>
    </div>
    &emsp;<div class="container">
        <div class="row">
            <div class="prd-inside" style="width:30%; text-align: center; background-color:#f2f2f2">
                <div class="prd-img-area">
                    <a href="product.html" class="prd-img image-hover-scale image-container">
                        <img src="https://png.pngtree.com/png-vector/20190130/ourmid/pngtree-beautiful-little-boy-playing-in-the-winter-snow-paintedwinterheavy-snowboyplayearmuffsillustrationcharacter-png-image_671189.jpg " style="width:300px;" data-src="images/skins/fashion/products/product-02-1.jpg" alt="Oversize Cotton Dress" class="js-prd-img lazyload fade-up">
                        <div class="foxic-loader"></div>
                        <div class="prd-big-squared-labels">

                        </div>
                    </a>
                    <div class="prd-circle-labels">
                        <!-- <a href="#" class="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"><i class="icon-heart-stroke"></i></a><a href="#" class="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0" title="Remove From Wishlist"><i class="icon-heart-hover"></i></a> -->
                        <!-- <a href="#" class="circle-label-qview js-prd-quickview prd-hide-mobile" data-src="ajax/ajax-quickview.html"><i class="icon-eye"></i><span>QUICK VIEW</span></a> -->

                        <div class="colorswatch-label colorswatch-label--variants js-prd-colorswatch">
                            <!-- <i class="icon-palette"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></i> -->
                            &emsp;<ul style="text-align:center">
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                            </ul>
                        </div>

                    </div>

                    <ul style="text-align: center;" class="list-options color-swatch">
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" data-src="images/skins/fashion/products/product-02-1.jpg" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" data-src="images/skins/fashion/products/product-02-3.jpg" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                    </ul>

                </div>
                <div class="prd-info">
                    <div class="prd-info-wrap">
                        <div class="prd-info-top">
                            <div class="prd-rating"></div>
                        </div>
                        <div class="prd-rating justify-content-center">
                            <Rating v-model="val2" :cancel="false" />
                        </div>
                        <div class="prd-tag" style="text-align: center;">
                            <p href="#">Fokic</p>
                        </div>
                        <h2 style="text-align:center">Leather Pegged Pants</h2>

                    </div>
                    <div class="prd-hovers">
                        <div class="prd-circle-labels">
                            <div><a href="#" class="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"><i class="icon-heart-stroke"></i></a><a href="#" class="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0" title="Remove From Wishlist"><i class="icon-heart-hover"></i></a></div>
                            <!-- <div class="prd-hide-mobile"><a href="#" class="circle-label-qview js-prd-quickview" data-src="ajax/ajax-quickview.html"><i class="icon-eye"></i><span>QUICK VIEW</span></a></div> -->
                        </div>&emsp;
                        <h6 style="text-align:center">$180</h6>&emsp;
                        <div class="prd-action">
                            <div class="prd-action-left">
                                <form action="#">
                                    <BaseButton style="width:340px" label="View Product" />
                                </form>&emsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm"> -->
            <div class="prd-inside" style="width:30%; text-align: center; background-color:#f2f2f2">
                <div class="prd-img-area">
                    <a href="product.html" class="prd-img image-hover-scale image-container">
                        <img src="https://png.pngtree.com/png-vector/20190130/ourmid/pngtree-beautiful-little-boy-playing-in-the-winter-snow-paintedwinterheavy-snowboyplayearmuffsillustrationcharacter-png-image_671189.jpg " style="width:300px;" data-src="images/skins/fashion/products/product-02-1.jpg" alt="Oversize Cotton Dress" class="js-prd-img lazyload fade-up">
                        <div class="foxic-loader"></div>
                        <div class="prd-big-squared-labels">

                        </div>
                    </a>
                    <div class="prd-circle-labels">
                        <!-- <a href="#" class="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"><i class="icon-heart-stroke"></i></a><a href="#" class="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0" title="Remove From Wishlist"><i class="icon-heart-hover"></i></a> -->
                        <!-- <a href="#" class="circle-label-qview js-prd-quickview prd-hide-mobile" data-src="ajax/ajax-quickview.html"><i class="icon-eye"></i><span>QUICK VIEW</span></a> -->

                        <div class="colorswatch-label colorswatch-label--variants js-prd-colorswatch">
                            <!-- <i class="icon-palette"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></i> -->
                            &emsp;<ul style="text-align:center">
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                            </ul>
                        </div>

                    </div>

                    <ul style="text-align: center;" class="list-options color-swatch">
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" data-src="images/skins/fashion/products/product-02-1.jpg" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" data-src="images/skins/fashion/products/product-02-3.jpg" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                    </ul>

                </div>
                <div class="prd-info">
                    <div class="prd-info-wrap">
                        <div class="prd-info-top">
                            <div class="prd-rating"></div>
                        </div>
                        <div class="prd-rating justify-content-center">
                            <Rating v-model="val2" :cancel="false" />
                        </div>
                        <div class="prd-tag" style="text-align: center;">
                            <p href="#">Fokic</p>
                        </div>
                        <h2 style="text-align:center">Leather Pegged Pants</h2>

                    </div>
                    <div class="prd-hovers">
                        <div class="prd-circle-labels">
                            <div><a href="#" class="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"><i class="icon-heart-stroke"></i></a><a href="#" class="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0" title="Remove From Wishlist"><i class="icon-heart-hover"></i></a></div>
                            <!-- <div class="prd-hide-mobile"><a href="#" class="circle-label-qview js-prd-quickview" data-src="ajax/ajax-quickview.html"><i class="icon-eye"></i><span>QUICK VIEW</span></a></div> -->
                        </div>&emsp;
                        <h6 style="text-align:center">$180</h6>&emsp;
                        <div class="prd-action">
                            <div class="prd-action-left">
                                <form action="#">
                                    <BaseButton style="width:200px" label="View Product" />
                                </form>&emsp;
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
           
            <div class="prd-inside" style="width:30%; text-align: center; background-color:#f2f2f2">
                <div class="prd-img-area">
                    <a href="product.html" class="prd-img image-hover-scale image-container">
                        <img src="https://png.pngtree.com/png-vector/20190130/ourmid/pngtree-beautiful-little-boy-playing-in-the-winter-snow-paintedwinterheavy-snowboyplayearmuffsillustrationcharacter-png-image_671189.jpg " style="width:300px;" data-src="images/skins/fashion/products/product-02-1.jpg" alt="Oversize Cotton Dress" class="js-prd-img lazyload fade-up">
                        <div class="foxic-loader"></div>
                        <div class="prd-big-squared-labels">

                        </div>
                    </a>
                    <div class="prd-circle-labels">
                        <!-- <a href="#" class="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"><i class="icon-heart-stroke"></i></a><a href="#" class="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0" title="Remove From Wishlist"><i class="icon-heart-hover"></i></a> -->
                        <!-- <a href="#" class="circle-label-qview js-prd-quickview prd-hide-mobile" data-src="ajax/ajax-quickview.html"><i class="icon-eye"></i><span>QUICK VIEW</span></a> -->

                        <div class="colorswatch-label colorswatch-label--variants js-prd-colorswatch">
                            <!-- <i class="icon-palette"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></i> -->
                            &emsp;<ul style="text-align:center">
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                                <a class="js-color-toggle" data-toggle="tooltip" data-placement="left" title="Color Name"><img src="https://www.smartick.com/blog/wp-content/uploads/Captura-75-1.png" style="width:30px;" alt=""></a>&emsp;
                            </ul>
                        </div>

                    </div>

                    <ul style="text-align: center;" class="list-options color-swatch">
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" data-src="images/skins/fashion/products/product-02-1.jpg" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                        <a href="#" class="js-color-toggle" data-toggle="tooltip" data-placement="right" title="Color Name"><img src="https://wallpaperaccess.com/full/2489629.jpg" style="width:30px;" data-src="images/skins/fashion/products/product-02-3.jpg" class="lazyload fade-up" alt="Color Name"></a>&emsp;
                    </ul>

                </div>
                <div class="prd-info">
                    <div class="prd-info-wrap">
                        <div class="prd-info-top">
                            <div class="prd-rating"></div>
                        </div>
                        <div class="prd-rating justify-content-center">
                            <Rating v-model="val2" :cancel="false" />
                        </div>
                        <div class="prd-tag" style="text-align: center;">
                            <p href="#">Fokic</p>
                        </div>
                        <h2 style="text-align:center">Leather Pegged Pants</h2>

                    </div>
                    <div class="prd-hovers">
                        <div class="prd-circle-labels">
                            <div><a href="#" class="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"><i class="icon-heart-stroke"></i></a><a href="#" class="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0" title="Remove From Wishlist"><i class="icon-heart-hover"></i></a></div>
                            <!-- <div class="prd-hide-mobile"><a href="#" class="circle-label-qview js-prd-quickview" data-src="ajax/ajax-quickview.html"><i class="icon-eye"></i><span>QUICK VIEW</span></a></div> -->
                        </div>&emsp;
                        <h6 style="text-align:center">$180</h6>&emsp;
                        <div class="prd-action">
                            <div class="prd-action-left">
                                <form action="#">
                                    <BaseButton style="width:200px" label="View Product" />
                                </form>&emsp;
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Rating from 'primevue/rating';
export default {
    components: {
        Rating
    },
    data() {
        return {
            selectedCity1: null,
            selectedCity2: null,
            val2: null,
            city: [{
                    names: '12'
                },
                {
                    names: '36'
                },
                {
                    names: '100'
                }
            ],
            cities: [{
                    name: 'Featured'
                },
                {
                    name: 'Rating'
                },
                {
                    name: 'Price'
                }
            ]
        }
    }
}
</script>
