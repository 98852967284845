<template>
<div class="form-check p-0">
    <input
      :id="id"
      class="form-check-input"
      type="Checkbox"
      :name="name"
      :checked="checked"
    />
    <!-- <Checkbox :id="id" class="form-check-input" inputId="city1" :checked="checked" :name="name" /> -->
    <label :for="id" class="">
      <slot />
    </label>
</div>
</template>

<script>
export default {
    name: "BaseCheckbox",
    props: {
        name: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        checked: Boolean,
    },
};
</script>
