var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Button',{class:[
    _vm.btntype !== 'primary' ? `p-button-${_vm.btntype}` : '',
    _vm.size !== '' ? `p-button-${_vm.size}` : '',
    _vm.btnClasses,
  ],attrs:{"label":_vm.label,"disabled":_vm.disabled,"icon":`
    ${
      _vm.loading
        ? 'pi pi-spin pi-spinner p-button-icon-left'
        : _vm.iconClass
        ? _vm.iconClass + ' p-button-icon-left'
        : ''
    }
  `,"badge":_vm.badgeValue,"badgeClass":_vm.badgeType !== 'primary' ? `p-badge-${_vm.badgeType}` : '',"type":_vm.type,"iconPos":_vm.iconPos},on:{"click":function($event){return _vm.$emit('click', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }