<template>
    <Dropdown
      :id="id"
      ref="inputField"
      :disabled="disabled"
      
      v-model="inputValue"
      
      
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :appendTo="appendTo"
      @change="$emit('change', $event)"
      
    />

    
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
  components: {
    Dropdown,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    //Dropdown label
    label: {
      type: String,
    },
    tooltipValue: {
      type: String,
    },
    // Dropdown reactive value
    value: {
      type: [String, Object, Array, Number],
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    iconShown: {
      type: Boolean,
      default: false,
    },

    // Dropdown disabled state
    disabled: {
      type: Boolean,
      default: false,
    },
    // Dropdown autofocus state
    autofocus: {
      type: Boolean,
      default: false,
    },
    //Dropdown options
    options: {
      type: Array,
      default: null,
    },

    //Dropdown option label
    optionLabel: {
      type: String,
      default: null,
    },
    //Dropdown option value
    optionValue: {
      type: String,
      default: null,
    },
    //Dropdown option diabled
    optionDisabled: {
      type: String,
      default: null,
    },
    appendTo: {
      type: String,
      default: "body",
    },
    
    //Dropdown display (chip or comma)
    display: {
      type: String,
      default: "comma",
    },
    

    
  },
  computed: {
    inputValue: {
      get: function () {
        
        return this.value;
      },
      set: function (newValue) {
  
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
