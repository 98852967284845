<template>
      <InputText
        :type="type"
        :id="id"
        ref="inputField"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        :autofocus="autofocus"
        v-model="inputValue"
        :min="min"
        :max="max"       
      />
</template>

<script>
import InputText from "primevue/inputtext";
export default {
  
  components: {
    InputText,
  },
  props: {
    autofocus: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
      validator: function (value) {
        // The value must match one of these strings
        return ["text", "password", "number"].indexOf(value) !== -1;
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
    },
    
    min: {
      type: String,
      default: "0",
    },
    max: {
      type: String,
      default: "",
    },
    labelShown: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    inputValue: {
      get: function () {
        return this.value;
      },
      // setter
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
