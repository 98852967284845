<template>
  <Message
    :severity="severity"
    :closable="closable"
    :sticky="sticky"
    :life="life"
    @close="$emit('close', $event)"
  >
    {{ message }}
  </Message>
</template>

<script>
import Message from "primevue/message";
export default {
  components: {
    Message,
  },
  props: {
    severity: {
      type: String,
    },
    message: {
      type: String,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    life: {
      type: Number,
      default: 10000,
    },
  },
  mounted() {
    // The message component does not have any native events
    // that are emitted when the modal is auto closed to which we can bind a callback.
    // Hence the below fix.
    setTimeout(() => {
      this.$emit("close");
    }, this.life);
  },
};
</script>
