<template>
  <main class="main-content">
    <div class="signUP-admin">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-5 col-md-5 p-0">
            <div class="signUP-admin-left signIn-admin-left position-relative">
              <div class="signUP-overlay">
                <!-- <img class="svg signupTop" src="../../assets/img/svg/signupTop.svg" alt="img" />
                                <img class="svg signupBottom" src="../../assets/img/svg/signupBottom.svg" alt="img" /> -->
              </div>
              <!-- End: .signUP-overlay  -->
              <div class="signUP-admin-left__content">
                <div
                  class="
                    text-capitalize
                    mb-md-30 mb-15
                    d-flex
                    align-items-center
                    justify-content-md-start justify-content-center
                  "
                >
                  <a
                    class="
                      wh-36
                      bg-primary
                      text-white
                      radius-md
                      mr-10
                      content-center
                    "
                    href=""
                    >a</a
                  >
                  <span class="text-dark">admin</span>
                </div>
                <h1>Bootstrap 4 Vue Web Application</h1>
              </div>
              <!-- End: .signUP-admin-left__content  -->
              <div class="signUP-admin-left__img">
                <img
                  class="img-fluid svg"
                  src="../../assets/img/svg/signupIllustration.svg"
                  alt="img"
                />
              </div>
              <!-- End: .signUP-admin-left__img  -->
            </div>
            <!-- End: .signUP-admin-left  -->
          </div>
          <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
            <div class="signUp-admin-right p-md-40 p-10">
              <div
                class="
                  signUp-topbar
                  d-flex
                  align-items-center
                  justify-content-md-end justify-content-center
                  mt-md-0
                  mb-md-0
                  mt-20
                  mb-1
                "
              >
                <p class="mb-0">
                  Already have an account?
                  <a href="" class="color-primary">
                    <router-link to="/"> Sign in</router-link>
                  </a>
                </p>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="row justify-content-center">
                    <div class="col-xl-7 col-lg-10 col-md-12">
                      <div class="edit-profile mt-md-25 mt-0">
                        <div class="card border-0">
                          <div
                            class="
                              card-header
                              border-0
                              pb-md-15 pb-10
                              pt-md-20 pt-10
                            "
                          >
                            <div class="edit-profile__title">
                              <h6>
                                Sign Up To
                                <span class="color-primary">Admin</span>
                              </h6>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="edit-profile__body">
                              <!-- <div class="form-group mb-20">
                                                            &emsp;<h6>Name</h6>
                                                        </div>
                                                        <InputText type="text" v-model="$v.Name.$model" :validate="$v.Name" placeholder="Full Name" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Name.required !== 'undefined' &&$v.Name.required=='' &&$v.Name.$error">
                                                            Firstname is required
                                                        </div>
                                                        <div class="form-group mb-20">
                                                            &emsp;<h6>Username</h6>
                                                        </div>
                                                        <InputText type="text" v-model="$v.Username.$model" :validate="$v.Username" placeholder="Username" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Username.required !== 'undefined' &&$v.Username.required=='' &&$v.Username.$error">
                                                            Lastname is required
                                                        </div> -->
                              <!-- <div class="form-group mb-20">
                                                                &emsp;<h6>Email Adress</h6>
                                                            </div>
                                                            <InputText type="text" v-model="$v.Email.$model" :validate="$v.Email" placeholder="name@example.com" />
                                                            <div class="validation-div mb-2" v-if="typeof $v.Email.required !== 'undefined' &&$v.Email.required=='' &&$v.Email.$error">
                                                                Emailid is required
                                                                </div>
                                                            <div class="form-group mb-15">
                                                                &emsp;<h6>Password</h6>
                                                            </div>
                                                            <Password v-model="$v.Password.$model" :validate="$v.Password" :feedback="false" />
                                                            <div class="validation-div mb-2" v-if="typeof $v.Password.required !== 'undefined' &&$v.Password.required=='' &&$v.Password.$error">
                                                                Password is required 
                                                                </div> -->
                              <div class="form-group mb-20 p-fluid">
                                <label for="First Name">First Name</label>
                                <BaseInput
                                  type="text"
                                  placeholder="First Name"
                                  v-model="$v.firstName.$model"
                                  :validate="$v.firstName"
                                />
                                <div
                                  class="validation-div mb-2"
                                  v-if="
                                    typeof $v.firstName.required !== 'undefined' &&
                                    $v.firstName.required == '' &&
                                    $v.firstName.$error
                                  "
                                >
                                First Name is required
                                </div>
                              </div>
                              <div class="form-group mb-20 p-fluid">
                                <label for="Last Name">Last Name</label>
                                <BaseInput
                                  type="text"
                                  placeholder="Last Name"
                                  v-model="$v.lastName.$model"
                                  :validate="$v.lastName"
                                />
                                <div
                                  class="validation-div mb-2"
                                  v-if="
                                    typeof $v.lastName.required !==
                                      'undefined' &&
                                    $v.lastName.required == '' &&
                                    $v.lastName.$error
                                  "
                                >
                                Last Name is required
                                </div>
                              </div>
                              <div class="form-group mb-20 p-fluid">
                                <label for="Email">Email Adress</label>
                                <BaseInput
                                  type="text"
                                  placeholder="Enter Your Email"
                                  v-model="$v.Email.$model"
                                  :validate="$v.Email"
                                />
                                <div
                                  class="validation-div mb-2"
                                  v-if="
                                    typeof $v.Email.required !== 'undefined' &&
                                    $v.Email.required == '' &&
                                    $v.Email.$error
                                  "
                                >
                                  Emailid is required
                                </div>
                                <div
                                  class="validation-div mb-2"
                                  v-if="!$v.Email.email"
                                >
                                  Emailid should be in correct format
                                </div>
                              </div>
                              <div class="form-group mb-15 p-fluid">
                                <label for="password-field">password</label>
                                <div class="position-relative">
                                  <Password
                                    v-model="$v.Password.$model"
                                    :validate="$v.Password"
                                    :feedback="false"
                                    toggleMask
                                    placeholder="Enter your password"
                                  />
                                  <div
                                    class="validation-div mb-2"
                                    v-if="
                                      typeof $v.Password.required !==
                                        'undefined' &&
                                      $v.Password.required == '' &&
                                      $v.Password.$error
                                    "
                                  >
                                    Password is required
                                  </div>
                                  <div
                                    class="validation-div mb-2"
                                    v-if="
                                      !$v.Password.min 
                                    "
                                  >
                                  Password length must be atleast
                                    {{ $v.Password.$params.min.min }}
                                    characters.
                                  </div>
                                </div>
                              </div>
                              <div class="form-group mb-15 p-fluid">
                                <label for="password-field">Confirm Password</label>
                                <div class="position-relative">
                                  <Password
                                    v-model="$v.confirmPassword.$model"
                                    :validate="$v.confirmPassword"
                                    :feedback="false"
                                    toggleMask
                                    placeholder="Enter your password"
                                  />
                                  <div
                                    class="validation-div mb-2"
                                    v-if="
                                      !$v.confirmPassword.sameAsPassword  
                                    "
                                  >
                                  Password and confirm password should be same
                                  </div>
                                  <div
                                    class="validation-div mb-2"
                                    v-else-if="
                                      typeof $v.confirmPassword.required !==
                                        'undefined' &&
                                      $v.confirmPassword.required == '' &&
                                      $v.confirmPassword.$error
                                    "
                                  >
                                    Confirm Password is required
                                  </div>
                                </div>
                              </div>
                              <div class="signUp-condition">
                                <div
                                  class="checkbox-theme-default custom-checkbox"
                                >
                                  <BaseCheckbox
                                    id="Product"
                                    class="checkbox"
                                    name="Product"
                                    value="BuyProduct"
                                  >
                                    <span class="checkbox-text"
                                      >Creating an account means you’re okay
                                      with our
                                      <a href="#" class="color-secondary"
                                        >Terms of Service</a
                                      >
                                      and
                                      <a href="#" class="color-secondary"
                                        >Privacy Policy</a
                                      >
                                      my preference</span
                                    ></BaseCheckbox
                                  >

                                  <!-- <Checkbox value="Chicago" v-model="Checkbox" /> Creating An Account Means You’re Okay With Our<span style="color:red"> Terms Of</span>
                                                                <span style="color:red"> Services&emsp;</span>And&emsp;<span style="color:red">Privacy Policy&emsp;</span>My Preference -->
                                </div>
                              </div>
                              &emsp;
                              <!-- <div class="button-group d-flex pt-1 justify-content-md-start justify-content-center button">
                                                            <Button label="Create Account" />
                                                        </div> -->
                              <div
                                class="
                                  button-group
                                  d-flex
                                  pt-1
                                  justify-content-md-start
                                  justify-content-center
                                "
                              >
                              <BaseButton
                              color="info"
                              :loading="loading"
                              :disabled="loading || $v.$invalid"
                              @click="login()"
                              label="Create Account"
                              class="
                                p-button-raised p-button-info
                                btn btn-primary btn-default btn-squared
                                mr-15
                                text-capitalize
                                lh-normal
                                px-50
                                py-15
                                signIn-createBtn
                              "
                            ></BaseButton>
                              </div>
                              <p
                                class="
                                  social-connector
                                  text-center
                                  mb-sm-25 mb-15
                                  mt-sm-30 mt-20
                                "
                              >
                                <span>Or</span>
                              </p>
                              <div
                                class="
                                  button-group
                                  d-flex
                                  align-items-center
                                  justify-content-md-start
                                  justify-content-center
                                "
                              >
                                <ul class="signUp-socialBtn">
                                  <button class="btn text-dark">
                                    <img
                                      class="svg"
                                      src="../../assets/img/svg/google.svg"
                                      alt="img"
                                    />
                                    Sign up with Google
                                  </button>
                                  <button class="btn text-dark">
                                    <img
                                      class="svg"
                                      src="../../assets/img/svg/facebook.svg"
                                      alt="img"
                                    />
                                  </button>
                                  <button class="btn text-dark">
                                    <img
                                      class="svg"
                                      src="../../assets/img/svg/twitter.svg"
                                      alt="img"
                                    />
                                  </button>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Password from "primevue/password";
// import Button from "primevue/button";
import { required , email , minLength , sameAs} from "vuelidate/lib/validators";

export default {
  components: {
    Password,
    // Button,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      Email: "",
      Password: "",
      confirmPassword: "",
      Checkbox: [],
    };
  },
  validations() {
    return {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      Email: {
        required,
        email
      },
      Password: {
        required,
        min: minLength(8),
      },
      confirmPassword:{
        required,
        sameAsPassword: sameAs(this.Password)
      }
    };
  },
};
</script>

<style>
.button {
  margin-left: 160px;
}
</style>
