<template>
  <div :class="[divclass]">
    <RadioButton
      :aria-label="ariaLabel"
      :id="id"
      :name="name"
      :checked="isChecked"
      :value="radioValue"
      v-model="inputValue"
      @change="$emit('change', $event.target.value)"
      :disabled="disabled"
      :class="[
        { 'p-invalid': typeof validate !== 'undefined' && validate.$error },
        inputClass,
      ]"
      @click="onClick"
    />
    <label v-if="labelShown" :for="id">
      {{ label }}
      <span class="text-danger" v-if="isRequired"> * </span>
    </label>
  </div>
</template>

<script>
import RadioButton from "primevue/radiobutton";
export default {
  components: {
    RadioButton,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Object,
      default: () => {},
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
    },
    divclass: {
      type: String,
      default: "",
    },
    labelShown: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hasGroup: {
      type: Boolean,
      default: true,
    },
    radioValue: {
      type: String,
      default: "",
    },
  },
  methods: {
    onChange($event) {
      this.$emit("change", $event.target.value);
    },
    onClick($event) {
      this.$emit("click", $event.target.value);
    },
  },
  computed: {
    isChecked() {
      return this.radioValue == this.value;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
  },
};
</script>
