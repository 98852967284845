var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main-content"},[_c('div',{staticClass:"signUP-admin"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_vm._m(0),_c('div',{staticClass:"col-xl-8 col-md-7 col-sm-8"},[_c('div',{staticClass:"signUp-admin-right content-center h-100 pb-30"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-sm-10"},[_c('div',{staticClass:"edit-profile mt-0"},[_c('div',{staticClass:"card border-0"},[_vm._m(1),_c('div',{staticClass:"card-body pt-20 pb-0"},[_c('div',{staticClass:"edit-profile__body"},[_c('div',{staticClass:"form-group mb-20 p-fluid"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Email Adress")]),_c('BaseInput',{attrs:{"type":"text","placeholder":"Enter Your Email","validate":_vm.$v.verifyEmail},model:{value:(_vm.$v.verifyEmail.$model),callback:function ($$v) {_vm.$set(_vm.$v.verifyEmail, "$model", $$v)},expression:"$v.verifyEmail.$model"}}),(
                                  typeof _vm.$v.verifyEmail.required !==
                                    'undefined' &&
                                  _vm.$v.verifyEmail.required == '' &&
                                  _vm.$v.verifyEmail.$error
                                )?_c('div',{staticClass:"validation-div mb-2"},[_vm._v(" Emailid is required ")]):_vm._e(),(!_vm.$v.verifyEmail.email)?_c('div',{staticClass:"validation-div mb-2"},[_vm._v(" Emailid should be in correct format ")]):_vm._e()],1),_c('div',{staticClass:"form-group mb-20 p-fluid"},[_c('div',{staticClass:"text-center"},[(!_vm.isotp)?_c('BaseButton',{staticClass:"p-button-info rounded-pill mt-3",attrs:{"label":"Send otp","type":"button"},on:{"click":function($event){return _vm.submit()}}},[_c('router-link',{staticClass:"text-secondary font-weight-bold",attrs:{"to":{
                                      name: '',
                                      params: { email: _vm.verifyEmail },
                                    }}})],1):_vm._e()],1),_c('div',{staticClass:"p-inputgroup mt-3 text-center input-group input-group-outline null ml-lg-0 ml-2"},[(_vm.isotp)?_c('BaseInput',{staticClass:"form-control form-control-lg",attrs:{"type":"number","placeholder":"OTP"},model:{value:(_vm.$v.verifyOTP.$model),callback:function ($$v) {_vm.$set(_vm.$v.verifyOTP, "$model", $$v)},expression:"$v.verifyOTP.$model"}}):_vm._e(),(
                                    !_vm.$v.verifyOTP.min 
                                  )?_c('div',{staticClass:"validation-div mb-2"},[_vm._v(" Otp length must be atleast "+_vm._s(_vm.$v.verifyOTP.$params.min.min)+" characters. ")]):(
                                    typeof _vm.$v.verifyOTP.required !==
                                      'undefined' &&
                                    _vm.$v.verifyOTP.required == '' &&
                                    _vm.$v.verifyOTP.$error
                                  )?_c('div',{staticClass:"validation-div mb-2"},[_vm._v(" Otp is required ")]):_vm._e()],1),(_vm.isotp)?_c('div',{staticClass:"text-center"},[_c('BaseButton',{staticClass:"p-button-info rounded-pill mt-3",attrs:{"label":"Submit","variant":"gradient","color":"light","fullWidth":""},on:{"click":function($event){return _vm.verifyotp()}}},[_c('router-link',{staticClass:"text-secondary font-weight-bold",attrs:{"to":{
                                      name: '',
                                      params: { email: _vm.verifyEmail },
                                    }}})],1)],1):_vm._e()]),_c('p',{staticClass:"mb-0 text-center fs-14 fw-500 text-gray text-capitalize"},[_vm._v(" return to "),_c('a',{staticClass:"color-primary",attrs:{"href":""}},[_c('router-link',{staticClass:"text-secondary",attrs:{"to":"/"}},[_vm._v(" Sign in")])],1)])])])])])])])])]),_c('div',{staticClass:"col-sm-1"})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-4 col-lg-5 col-md-5 p-0"},[_c('div',{staticClass:"signUP-admin-left signIn-admin-left position-relative"},[_c('div',{staticClass:"signUP-overlay"}),_c('div',{staticClass:"signUP-admin-left__content"},[_c('div',{staticClass:"text-capitalize mb-md-30 mb-15 d-flex align-items-center justify-content-md-start justify-content-center"},[_c('a',{staticClass:"wh-36 bg-primary text-white radius-md mr-10 content-center",attrs:{"href":""}},[_vm._v("a")]),_c('span',{staticClass:"text-dark"},[_vm._v("admin")])]),_c('h1',[_vm._v("Bootstrap 4 Vue Web Application")])]),_c('div',{staticClass:"signUP-admin-left__img"},[_c('img',{staticClass:"img-fluid svg",attrs:{"src":require("../../assets/img/svg/signupIllustration.svg"),"alt":"img"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header border-0 pt-0 pb-0"},[_c('div',{staticClass:"signUp-header-top mt-md-0 mt-30"},[_c('h6',[_vm._v("Forgot Password?")]),_c('p',{staticClass:"mt-md-45 mt-20"},[_vm._v(" Enter the email address you used when you joined and we’ll send you instructions to reset your password. ")])])])
}]

export { render, staticRenderFns }