<template>
<div class="columns-2 col-lg-8 col-md-7 col-sm-8 order-md-1 order-0">
    <!-- Start: Top Bar -->
    <div class="shop_products_top_filter">
        <div class="project-top-wrapper d-flex flex-wrap align-items-center">
            <div class="project-top-left d-flex flex-wrap align-items-center">
                <div class="project-search shop-search  global-shadow ">&emsp;
                    <form action="/" class="d-flex align-items-center user-member__form" style="padding:10%">
                        <span data-feather="search"></span>

                        <BaseInput class="" type="" placeholder="Search" aria-label="Search" />&emsp;
                    </form>
                </div>
                <span class="project-result-showing fs-14 color-gray ml-xl-25 mr-xl-0 text-center mt-lg-0 mt-20">Showing
                    <span>1–8</span> of <span>86</span>
                    results</span>
            </div>
            <div class="project-top-right d-flex flex-wrap align-items-center">&emsp;
                <div class="project-category flex-wrap d-flex align-items-center">&emsp;
                    <!-- &emsp;<p class="fs-14 color-gray text-capitalize">sort by:</p> -->
                    <!-- <div class="project-tap b-light"> -->
                    <ul class="nav px-1 " id="ap-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="ap-overview-tab" data-toggle="pill" href="#ap-overview" role="tab" aria-controls="ap-overview" aria-selected="true">Top rated</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="timeline-tab" data-toggle="pill" href="#timeline" role="tab" aria-controls="timeline" aria-selected="false">Popular</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="activity-tab" data-toggle="pill" href="#activity" role="tab" aria-controls="activity" aria-selected="false">Newest</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="draft-tab" data-toggle="pill" href="#draft" role="tab" aria-controls="draft" aria-selected="false">price</a>
                        </li>
                    </ul>
                    <!-- </div> -->
                </div>
                <div class="project-icon-selected content-center mt-lg-0 mt-25">
                    <div class="listing-social-link pb-lg-0 pb-xs-2">
                        <div class="icon-list-social d-flex">
                            <a class="icon-list-social__link rounded-circle icon-list-social__style justify-content-center active ml-xl-20 mr-20" href="#">
                                <span data-feather="grid"></span></a>
                            <a class="icon-list-social__link rounded-circle icon-list-social__style justify-content-center  " href="product-list.html">
                                <span data-feather="list"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End: Top Bar -->
    <div class="tab-content mt-25" id="ap-tabContent">
        <div class="tab-pane fade show active" id="ap-overview" role="tabpanel" aria-labelledby="ap-overview-tab">
            <!-- Start: Shop Item -->
            <div class="row product-page-list justify-content-center">
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/digital-chair.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Montes scelerisque</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span> -->
                                            <!-- <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val2" :cancel="false" />
                                            <span class="stars-rating__point">5</span>
                                            &emsp;<span>778</span><span>Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />

                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/glass-cup.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Leo sodales varius</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val3" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/chair2.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Hanging lamp berlingo</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val4" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/chair.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">commodo adipiscing</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val5" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/plate.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Leo sodales varius</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val6" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/chair3.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Hanging lamp berlingo</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val7" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/juse.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">commodo adipiscing</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val8" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/coffe-glass.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Leo sodales varius</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val9" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/boss-chair.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Montes scelerisque</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val10" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/only-juss.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Leo sodales varius</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val11" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <!-- <button type="button" class="content-center"> -->
                                        <i class="lar la-heart icon"></i>
                                        <!-- </button> -->
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/flip-chair.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">Hanging lamp berlingo</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val12" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="cus-xl-3 col-lg-6 col-md-11 col-12 mb-30 px-10">

                    <div class="card product product--grid">
                        <div class="h-100">
                            <div class="product-item">
                                <div class="product-item__image">
                                    <span class="like-icon">
                                        <button type="button" class="content-center">
                                            <i class="lar la-heart icon"></i>
                                        </button>
                                    </span>
                                    <a href="#"><img class="card-img-top img-fluid" src="../../assets/img/chair.png" alt="digital-chair"></a>
                                </div>
                                <div class="card-body px-20 pb-25 pt-20">
                                    <div class="product-item__body text-capitalize">
                                        <a href="product-details.html">
                                            <h6 class="card-title">commodo adipiscing</h6>
                                        </a>
                                        <div class="d-flex align-items-center mb-10 flex-wrap">
                                            <span class="product-desc-price">$200.00</span>
                                            <span class="product-price">$100.00</span>
                                            <span class="product-discount">50% Off</span>
                                        </div>
                                    </div>
                                    <div class="product-item__footer">
                                        <div class="stars-rating d-flex align-items-center flex-wrap">
                                            <!-- <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star active"></span>
                                            <span class="star-icon las la-star-half-alt active"></span> -->
                                            <Rating v-model="val13" :cancel="false" />
                                            <span class="stars-rating__point">4.9</span>
                                            <span class="stars-rating__review">
                                                &emsp;<span>778</span> Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product-item__button d-flex mt-20 flex-wrap">

                                        <BaseButton label="Add to Cart" />
                                        &emsp;
                                        <BaseButton label="Buy Now" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- End: Shop Item -->
        </div>

    </div>
</div>
</template>

<script>
import Rating from 'primevue/rating';
export default {
    components: {
        Rating
    },
    data() {
        return {
            val2: '',
            val3: '',
            val4: '',
            val5: '',
            val6: '',
            val7: '',
            val8: '',
            val9: '',
            val10: '',
            val11: '',
            val12: '',
            val13: ''
        }
    }
}
</script>
