<template>
<body class="layout-light side-menu overlayScroll">
    <main class="main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="shop-breadcrumb">

                        <div class="breadcrumb-main">&emsp;
                            <h4 class="text-capitalize breadcrumb-title">Edit Category</h4>&emsp;
                        </div>
                    </div>
                    <div class="global-shadow border px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                        <div class="row justify-content-center">
                            <div class="col-xl-7 col-lg-10">
                                <div class="mx-sm-30 mx-20 ">
                                    <!-- Start: card -->
                                    <div class="card add-product p-sm-30 p-20 mb-30">
                                        <div class="card-body p-0">
                                            <div class="card-header">
                                                <h4 class="fw-500">Edit Category</h4>
                                            </div>
                                            <!-- Start: card body -->
                                            <div class="add-product__body px-sm-40 px-20">
                                                <!-- Start: form -->
                                                <form>
                                                    <!-- form group -->
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Category name</h6><br>
                                                        <BaseInput type="text" v-model="$v.Categoryname.$model" :validate="$v.Categoryname" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Categoryname.required !== 'undefined' &&$v.Categoryname.required=='' &&$v.Categoryname.$error">
                                                            Category name is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group">&emsp;
                                                        <div class="countryOption">
                                                            <h6>
                                                                Category Image
                                                            </h6><br>
                                                            <BaseImageUpload mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="button-group add-product-btn d-flex justify-content-end mt-40">
                                        <BaseButton label="cancel" />&emsp;
                                        <BaseButton label="Save Product" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</body>
</template>

<script>
import {
    required
} from 'vuelidate/lib/validators';
export default {
    data(){
        return{
            Categoryname:''
        }
    },
    validations(){
        return{
            Categoryname:{
                required
            }
        }
    }
}
</script>

