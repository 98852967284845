import Vue from 'vue'
import Router from 'vue-router'
import Loginpage from '../components/layout/LoginPage.vue'
import Registartionpage from '../components/layout/RegistrationPage.vue'
import Sidebar from '../components/layout/Sidebar/SideBar.vue'
import Forgetpassword from '../components/layout/ForgetPassword.vue'
import profileList from '../components/profile/profileList.vue'
import dashboardList from '../components/Dashboard/dashboardList.vue'
import addEditProduct from '../components/Product/addEditProduct.vue'
import addEditCategory from '../components/Category/addEditCategory.vue'
import categoryList from '../components/Category/categoryList.vue'
import changePassword from '../components/layout/changePassword.vue'
import productList from '../components/Product/productList.vue'
import billAddress from '../components/BillAddress/billAddress.vue'
import cartList from '../components/Cart/cartList.vue'
import orderList from '../components/Order/orderList.vue'
import OrderHistory from '../components/Order/OrderHistory.vue'
import wishList from '../components/WishList/wishList.vue'
import footerPage from '../components/layout/footerPage.vue'
import headerPage from '../components/layout/headerPage.vue'
// import CheckoutPayment from '../components/Checkout/CheckoutPayment.vue'

Vue.use(Router)

export default new Router({
    routes:[
        {
            path:"/",
            name:'Loginpage',
            component:Loginpage,
            meta:{
                layout: "PublicLayout"
            }, 
        },
        {
            path:"/Registartionpage",
            name:'Registartionpage',
            component:Registartionpage,
            meta:{
                layout: "PublicLayout"
            }, 
        },
        {
            path:"/Sidebar",
            name:'Sidebar',
            component:Sidebar
        },
        {
            path:"/Forgetpassword",
            name:'Forgetpassword',
            component:Forgetpassword,
            meta:{
                layout: "PublicLayout"
            }, 
        },
        {
            path:"/profileList",
            name:'profileList',
            component:profileList
        },
        {
            path:"/dashboardList",
            name:'dashboardList',
            component:dashboardList
        },
        {
            path:"/addEditProduct",
            name:'addEditProduct',
            component:addEditProduct
        },
        {
            path:"/addEditCategory",
            name:'addEditCategory',
            component:addEditCategory
        },
        {
            path:"/changePassword",
            name:'changePassword',
            component:changePassword
        },
        {
            path:"/productList",
            name:'productList',
            component:productList
        },
        {
            path:"/billAddress",
            name:'billAddress',
            component:billAddress
        },
        {
            path:"/cartList",
            name:'cartList',
            component:cartList
        },
        {
            path:"/categoryList",
            name:'categoryList',
            component:categoryList
        },
        {
            path:"/orderList",
            name:'orderList',
            component:orderList
        },
        {
            path:"/OrderHistory",
            name:'OrderHistory',
            component:OrderHistory
        },
        {
            path:"/wishList",
            name:'wishList',
            component:wishList
        },
        {
            path:"/footerPage",
            name:'footerPage',
            component:footerPage
        },
        {
            path:"/headerPage",
            name:'headerPage',
            component:headerPage 
        }
    ]
})
