<template>
<div class="col-md-14 aside" style="padding:2%;">
    &emsp;<h1 class="mb-3">My Wishlist</h1>
    <div class="row">
        &emsp;<div class="col-sm">
            <Card style="width: 20em">
                <template #header>
                    <img alt="user header" src="../../assets/img/166.png">
                </template>
                <template #title>
                    Advanced Card
                </template>
                <template>
                    Card subtitle
                </template>
                <template>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                        quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                </template>
                <template #footer>
                    <BaseButton icon="pi pi-check" label="Save" />&emsp;
                    <BaseButton icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em" />
                </template>
            </Card>
        </div>
        <div class="col-sm">

            <Card style="width: 20em">
                <template #header>
                    <img alt="user header" src="../../assets/img/166.png">
                </template>
                <template #title>
                    Advanced Card
                </template>
                <template>
                    Card subtitle
                </template>
                <template>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                        quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                </template>
                <template #footer>
                    <BaseButton icon="pi pi-check" label="Save" />
                    <BaseButton icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em" />
                </template>
            </Card>

        </div>
        
    </div>
</div>
</template>

<script>
import Card from 'primevue/card';
// import Rating from 'primevue/rating';
export default {
    components: {
        Card,
        // Rating
    },
    data() {
        return {
            val2: null
        }
    }
}
</script>
