<template>
<body class="layout-light side-menu overlayScroll">
    <main class="main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="shop-breadcrumb">
                    </div>
                    <div class="global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                        <div class="row justify-content-center">
                            <!-- <div class="col-xl-7 col-lg-10"> -->
                                <div class="mx-sm-30 mx-20 ">
                                    <!-- Start: card -->
                                    <div class="card add-product p-sm-30 p-20 mb-30" style="width:200%">
                                        <div class="card-body p-0">
                                            <div class="card-header">
                                                <h4 class="fw-500">Change Password</h4>
                                            </div>
                                            <!-- Start: card body -->
                                            <div class="add-product__body px-sm-40 px-20">
                                                <!-- Start: form -->
                                                <form>
                                                    <!-- form group -->
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Current Password</h6><br>
                                                        <Password v-model="$v.CurrentPassword.$model" :validate="$v.CurrentPassword" :feedback="false" toggleMask placeholder="Enter your password" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.CurrentPassword.required !== 'undefined' &&$v.CurrentPassword.required=='' &&$v.CurrentPassword.$error">
                                                            Current Password is required
                                                        </div>
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>New Password</h6><br>
                                                        <Password v-model="$v.NewPassword.$model" :validate="$v.NewPassword" :feedback="false" toggleMask placeholder="Enter your password" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.NewPassword.required !== 'undefined' &&$v.NewPassword.required=='' &&$v.NewPassword.$error">
                                                            New Password is required
                                                        </div>
                                                        <div class="validation-div mb-2" v-if="
                                      !$v.NewPassword.min 
                                    ">
                                                   Password length must be atleast  characters
                                                    {{ $v.NewPassword.$params.min.min }}
                                                    characters.
                                                </div>
                                               
                                                    </div>
                                                    <div class="form-group p-fluid">&emsp;
                                                        <h6>Confirm Password</h6><br>
                                                        <Password v-model="$v.ConfirmPassword.$model" :validate="$v.ConfirmPassword" :feedback="false" toggleMask placeholder="Enter your password" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.ConfirmPassword.required !== 'undefined' &&$v.ConfirmPassword.required=='' &&$v.ConfirmPassword.$error">
                                                            Confrim Password is required
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-group add-product-btn d-flex justify-content-end mt-40">
                                        <BaseButton label="Submit" />
                                    </div>
                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</body>
</template>

<script>
import { required,minLength } from "vuelidate/lib/validators";
import Password from "primevue/password";
export default {
    components: {
        Password
    },
    data(){
        return{
            CurrentPassword:'',
            NewPassword:'',
            ConfirmPassword:''
        }
    },
    validations(){
        return{
            CurrentPassword:{
                required
            },
            NewPassword:{
                required,
                min: minLength(8)
            },
            ConfirmPassword:{
                required
            }
        }
    }
}
</script>
