<template>
  <main class="main-content">
    <div class="signUP-admin">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-5 col-md-5 p-0">
            <div class="signUP-admin-left signIn-admin-left position-relative">
              <div class="signUP-overlay">
                <!-- <img class="svg signupTop" src="../../assets/img/svg/signupTop.svg" alt="img" />
                                    <img class="svg signupBottom" src="../../assets/img/svg/signupBottom.svg" alt="img" />  -->
              </div>
              <!-- End: .signUP-overlay  -->
              <div class="signUP-admin-left__content">
                <div
                  class="
                    text-capitalize
                    mb-md-30 mb-15
                    d-flex
                    align-items-center
                    justify-content-md-start justify-content-center
                  "
                >
                  <a
                    class="
                      wh-36
                      bg-primary
                      text-white
                      radius-md
                      mr-10
                      content-center
                    "
                    href=""
                    >a</a
                  >
                  <span class="text-dark">admin</span>
                </div>
                <h1>Bootstrap 4 Vue Web Application</h1>
              </div>
              <!-- End: .signUP-admin-left__content  -->
              <div class="signUP-admin-left__img">
                <img
                  class="img-fluid svg"
                  src="../../assets/img/svg/signupIllustration.svg"
                  alt="img"
                />
              </div>
              <!-- End: .signUP-admin-left__img  -->
            </div>
            <!-- End: .signUP-admin-left  -->
          </div>
          <!-- End: .col -->
          <div class="col-xl-8 col-md-7 col-sm-8">
            <div class="signUp-admin-right content-center h-100 pb-30">
              <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <div class="row justify-content-center">
                    <div class="col-md-8 col-sm-10">
                      <div class="edit-profile mt-0">
                        <div class="card border-0">
                          <div class="card-header border-0 pt-0 pb-0">
                            <div class="signUp-header-top mt-md-0 mt-30">
                              <h6>Forgot Password?</h6>
                              <p class="mt-md-45 mt-20">
                                Enter the email address you used when you joined
                                and we’ll send you instructions to reset your
                                password.
                              </p>
                            </div>
                          </div>
                          <div class="card-body pt-20 pb-0">
                            <div class="edit-profile__body">
                              <div class="form-group mb-20 p-fluid">
                                <label for="username">Email Adress</label>
                                <BaseInput
                                  type="text"
                                  placeholder="Enter Your Email"
                                  v-model="$v.verifyEmail.$model"
                                  :validate="$v.verifyEmail"
                                />
                                <div
                                  class="validation-div mb-2"
                                  v-if="
                                    typeof $v.verifyEmail.required !==
                                      'undefined' &&
                                    $v.verifyEmail.required == '' &&
                                    $v.verifyEmail.$error
                                  "
                                >
                                  Emailid is required
                                </div>
                                <div
                                  class="validation-div mb-2"
                                  v-if="!$v.verifyEmail.email"
                                >
                                  Emailid should be in correct format
                                </div>
                              </div>
                              <div class="form-group mb-20 p-fluid">
                                <div class="text-center">
                                  <BaseButton
                                    v-if="!isotp"
                                    label="Send otp"
                                    class="p-button-info rounded-pill mt-3"
                                    type="button"
                                    @click="submit()"
                                  >
                                    <router-link
                                      :to="{
                                        name: '',
                                        params: { email: verifyEmail },
                                      }"
                                      class="text-secondary font-weight-bold"
                                      ></router-link
                                    >
                                  </BaseButton>
                                </div>

                                <div
                                  class="
                                    p-inputgroup
                                    mt-3
                                    text-center
                                    input-group input-group-outline
                                    null
                                    ml-lg-0 ml-2
                                  "
                                >
                                  <BaseInput
                                    type="number"
                                    v-if="isotp"
                                    class="form-control form-control-lg"
                                    v-model="$v.verifyOTP.$model"
                                    placeholder="OTP"
                                  />

                                  <div
                                    class="validation-div mb-2"
                                    v-if="
                                      !$v.verifyOTP.min 
                                    "
                                  >
                                  Otp length must be atleast
                                    {{ $v.verifyOTP.$params.min.min }}
                                    characters.
                                  </div>
                                  <div
                                    class="validation-div mb-2"
                                    v-else-if="
                                      typeof $v.verifyOTP.required !==
                                        'undefined' &&
                                      $v.verifyOTP.required == '' &&
                                      $v.verifyOTP.$error
                                    "
                                  >
                                    Otp is required
                                  </div>
                                </div>
                                <div class="text-center" v-if="isotp">
                                <BaseButton
                                    label="Submit"
                                    variant="gradient"
                                    color="light"
                                    fullWidth
                                    class="p-button-info rounded-pill mt-3"
                                    @click="verifyotp()"
                                  >
                                    <router-link
                                      :to="{
                                        name: '',
                                        params: { email: verifyEmail },
                                      }"
                                      class="text-secondary font-weight-bold"
                                      ></router-link
                                    >
                                  </BaseButton>
                                </div>

                                <!-- <p class="mt-4 text-sm text-center">
                                  <router-link
                                    :to="{ name: 'LoginPage' }"
                                    class="text-secondary font-weight-bold"
                                    >Back</router-link
                                  >
                                </p> -->
                              </div>
                              <p
                                class="
                                  mb-0
                                  text-center
                                  fs-14
                                  fw-500
                                  text-gray text-capitalize 
                                "
                              >
                                return to
                                <a href="" class="color-primary">
                                  <router-link to="/" class="text-secondary">
                                    Sign in</router-link
                                  >
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- End: .card -->
                      </div>
                      <!-- End: .edit-profile -->
                    </div>
                    <!-- End: col -->
                  </div>
                </div>
                <!-- End: .signUp-admin-right -->
              </div>
              <!-- End: .col -->
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <!-- End: .signUP-admin -->
      </div>
    </div>
  </main>
</template>

<script>
import { VerifyEmail, VerifyOTP } from "@/api/AuthApi.js";
import {
  required,
  email,
  minLength, 
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      isotp: false,
      verifyEmail: "",
      verifyOTP: null,
      email: this.verifyEmail,
    };
  },
  methods: {
    submit() {
      const data = {
        email: this.verifyEmail,
      };
      console.log(data);
      VerifyEmail(data).then((response) => {
        const params = this.verifyEmail;
        localStorage.setItem("params", params);

        if (response.status === 200) {
          this.isotp = !this.isotp;
        }
      });
    },
    verifyotp() {
      const data = {
        otp: this.verifyOTP,
      };
      VerifyOTP(data).then((response) => {
        if (response.status === 200) {
          this.$router.push("/changePassword");
        }
      });
    },
  },
  validations() {
    return {
      verifyEmail: {
        required,
        email,
      },
      verifyOTP: {
        required,
        min: minLength(8),
      },
    };
  },
};
</script>
