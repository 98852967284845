<template>
<div class="col-xxl-9 col-lg-8 col-sm-7 body" style="padding:3%;">
    <div class="mb-50">
        <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade  show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <!-- Edit Profile -->
                <div class="edit-profile mt-25">
                    <div class="card">
                        <div class="card-header px-sm-25 px-3">
                            <div class="edit-profile__title">
                                <h6>Edit Profile</h6>
                                <p class="fs-13 color-light fw-400">Set up your personal
                                    information</p>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-xxl-4 col-lg-10 col-sm-10">
                                    <div class="edit-profile__body mx-lg-20">
                                        <form>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-group mb-20 p-fluid">
                                                        <h4>First Name</h4><br>
                                                        <InputText type="text" v-model="$v.Fname.$model" :validate="$v.Fname" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Fname.required !== 'undefined' &&$v.Fname.required=='' &&$v.Fname.$error">
                                                            Firstname is required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col">
                                                    <div class="form-group mb-20 p-fluid">
                                                        <h4>Last Name</h4><br>
                                                        <InputText type="text" v-model="$v.Lname.$model" :validate="$v.Lname" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Lname.required !== 'undefined' &&$v.Lname.required=='' &&$v.Lname.$error">
                                                            Lastname is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-20 p-fluid">
                                                <h4>Email</h4><br>
                                                <InputText type="text" v-model="$v.Email.$model" :validate="$v.Email" />
                                                <div class="validation-div mb-2" v-if="typeof $v.Email.required !== 'undefined' &&$v.Email.required=='' &&$v.Email.$error">
                                                    Emailid is required
                                                </div>
                                                <div class="validation-div mb-2" v-if="!$v.Email.email">
                                                    Emailid should be in correct format
                                                </div>
                                            </div>
                                            <div class="form-group mb-20 p-fluid">
                                                <h4>Mobile No</h4><br>
                                                <InputText type="number" v-model="$v.Mobile.$model" :validate="$v.Mobile" />
                                                <div class="validation-div mb-2" v-if="typeof $v.Mobile.required !== 'undefined' &&$v.Mobile.required=='' &&$v.Mobile.$error">
                                                    Mobile no is required
                                                </div>
                                                <div class="validation-div mb-2" v-if="
                                      !$v.Mobile.min 
                                    ">
                                                    Mobile no length must be atleast
                                                    {{ $v.Mobile.$params.min.min }}
                                                    characters.
                                                </div>
                                                <div class="validation-div mb-2" v-if="
                                      !$v.Mobile.max 
                                    ">
                                                    Mobile no length must be atleast
                                                    {{ $v.Mobile.$params.max.max }}
                                                    characters.
                                                </div>
                                            </div>
                                            <div class="form-group mb-20 p-fluid">
                                                <h4>Address</h4><br>
                                                <InputText type="text" v-model="$v.Address.$model" :validate="$v.Address" />
                                                <div class="validation-div mb-2" v-if="typeof $v.Address.required !== 'undefined' &&$v.Address.required=='' &&$v.Address.$error">
                                                    Address is required
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm">
                                                    <div class="form-group mb-20 p-fluid">
                                                        <h4>City</h4><br>
                                                        <InputText type="text" v-model="$v.City.$model" :validate="$v.City" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.City.required !== 'undefined' &&$v.City.required=='' &&$v.City.$error">
                                                            City is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm">
                                                    <div class="form-group mb-20 p-fluid">
                                                        <h4>State</h4><br>
                                                        <InputText type="text" v-model="$v.State.$model" :validate="$v.State" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.State.required !== 'undefined' &&$v.State.required=='' &&$v.State.$error">
                                                            State is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm">
                                                    <div class="form-group mb-20 p-fluid">
                                                        <h4>Zip code</h4><br>
                                                        <InputText type="number" v-model="$v.Zipcode.$model" :validate="$v.Zipcode" />
                                                        <div class="validation-div mb-2" v-if="typeof $v.Zipcode.required !== 'undefined' &&$v.Zipcode.required=='' &&$v.Zipcode.$error">
                                                            Zipcode is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button-group d-flex flex-wrap pt-30 mb-15">

                                                <BaseButton label="Update Profile" />

                                                <BaseButton label="Cancel" />

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Edit Profile End -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import InputText from 'primevue/inputtext';

import {
    required,
    email,
    minLength
} from 'vuelidate/lib/validators';
import maxLength from 'vuelidate/lib/validators/maxLength';
export default {
    components: {

        InputText,
    },
    data() {
        return {
            Fname: "",
            Lname: "",
            Email: "",
            Mobile: "",
            Address: "",
            City: "",
            State: "",
            Zipcode: ""
        }
    },
    validations() {
        return {
            Fname: {
                required
            },
            Lname: {
                required
            },
            Email: {
                required,
                email
            },
            Mobile: {
                required,
                min: minLength(10),
                max: maxLength(10)
            },
            Address: {
                required
            },
            City: {
                required
            },
            State: {
                required
            },
            Zipcode: {
                required
            }

        }

    }
}
</script>
