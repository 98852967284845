<template>
  <div
    :class="{
      'form-group--error': typeof validate !== 'undefined' && validate.$error,
      'form-group': hasGroup,
    }"
  >
    <label v-if="labelShown" :for="id">
      {{ label }}
      <span class="text-danger" v-if="isRequired"> * </span>
      <span v-if="iconShown" v-btooltip :tooltip="tooltipValue">
        <i class="fas fa-info-circle gray-text"></i>
      </span>
    </label>

    <Calendar
      :id="id"
      ref="calendar"
      :selectionMode="selectionMode"
      :dateFormat="actualDateFormat"
      :showIcon="showIcon"
      :icon="calendarIcon"
      :numberOfMonths="numberOfMonths"
      :monthNavigator="monthNavigator"
      :yearNavigator="yearNavigator"
      :yearRange="yearRange"
      :minDate="minDate"
      :maxDate="maxDate"
      :view="view"
      :manualInput="manualInput"
      :disabled="disabled"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :showButtonBar="showButtonBar"
      :appendTo="appendTo"
      :timeOnly="timeOnly"
      :showTime="showTime"
      :showSeconds="showSeconds"
      :hourFormat="hourFormat"
      :class="[
        { 'p-invalid': typeof validate !== 'undefined' && validate.$error },
        inputClass,
        'form-control',
      ]"
      v-model="inputValue"
      @keydown.enter="$emit('keydown-enter')"
      @date-select="$emit('date-select')"
      @clear-click="$emit('clear-click')"
    />

    <BaseFormMessage
      v-if="typeof validate !== 'undefined'"
      :validate="validate"
      :label="label"
    />
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
export default {
  inheritAttrs: false,
  components: {
    Calendar,
  },
  props: {
    autofocus: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    tooltipValue: {
      type: String,
    },
    value: {
      type: [String, Number, Date, Array],
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    selectionMode: {
      type: String,
      default: "single",
    },
    numberOfMonths: {
      type: Number,
      default: 1,
    },
    dateFormat: {
      type: String,
      default: "mm/dd/yy",
    },
    iconShown: {
      type: Boolean,
      default: false,
    },
    showOtherMonths: {
      type: Boolean,
      default: true,
    },
    selectOtherMonths: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    calendarIcon: {
      type: String,
      default: "pi pi-calendar",
    },
    monthNavigator: {
      type: Boolean,
      default: false,
    },
    yearNavigator: {
      type: Boolean,
      default: false,
    },
    yearRange: {
      type: String,
      default: () => {
        const d = new Date();
        return `1950:${d.getFullYear() + 10}`;
      },
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    manualInput: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: "date",
    },

    validate: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasGroup: {
      type: Boolean,
      default: true,
    },
    showButtonBar: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    labelShown: {
      type: Boolean,
      default: false,
    },
    appendTo: {
      type: String,
      default: "body",
    },
    timeOnly: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    showSeconds: {
      type: Boolean,
      default: false,
    },
    hourFormat: {
      type: String,
      default: "24",
    },
  },
  methods: {
    getNextSibling(elem, selector) {
      var sibling = elem.nextElementSibling;
      if (!selector) return sibling;
      while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
      }
    },
  },
  computed: {
    inputValue: {
      get: function () {
        return this.value;
      },
      // setter
      set: function (newValue) {
        if (
          this.selectionMode === "range" &&
          newValue !== null &&
          newValue[1]
        ) {
          document.getElementsByClassName("p-datepicker")[0].remove();
          const calendarbutton = this.getNextSibling(
            document.getElementById(this.id),
            ".p-button"
          );
          calendarbutton.click();
        }
        const tmpValue = newValue === null ? "" : newValue;
        this.$emit("input", tmpValue);
      },
    },
    actualDateFormat() {
      let finalFormat = this.dateFormat;
      switch (this.dateFormat) {
        case "DD/MM/YYYY":
          finalFormat = "dd/mm/yy";
          break;
        case "DD/MM/YY":
          finalFormat = "dd/mm/y";
          break;
        case "MM/DD/YYYY":
          finalFormat = "mm/dd/yy";
          break;
        case "MM/DD/YY":
          finalFormat = "mm/dd/y";
          break;
        case "YYYY/MM/DD":
          finalFormat = "yy/mm/dd";
          break;
        case "YY/MM/DD":
          finalFormat = "y/mm/dd";
          break;
      }
      return finalFormat;
    },
  },
};
</script>
