<template>
<div class="contents">

    <div class="container-fluid">
        <div class="row">

        </div>
    </div>
    &emsp;<h4 class="text-capitalize breadcrumb-title" style="padding:3%;">product cart</h4>&emsp;
    <div class="container-fluid">
        <div class="cartPage  global-shadow border pr-sm-30 pl-sm-30  p-15 py-sm-30 bg-white radius-xl w-100 mb-30">
            <div class="row justify-content-center">
                <div class="cus-xl-9 col-12 ">
                    <div class="product-cart mb-sm-0 mb-20">
                        <div class="table-responsive">
                            <DataTable :value="customers" :paginator="true" :rows="10" responsiveLayout="scroll" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                                <Column field="name" header="product_name"></Column>
                                <Column field="productprice" header="product_price"></Column>
                                <Column field="subtotal" header="subTotal"></Column>
                                <Column field="quantity" header="Quantity"></Column>
                                <Column field="productimage" header="product_image"></Column>
                                <Column field="upload Prescription" header="Upload Dr. Prescription"></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
export default {
    components: {
        DataTable,
        Column
    },
    data() {
        return {
            customers: null,
            data: [{
                "name": "1000",
                "productprice":"200",
                "subtotal":"1200",
                "quantity":"1", 
            }]
        }
    },
    mounted(){
        this.customers = this.data
    }
    
}
</script>
