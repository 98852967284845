import apiService from "../services/ApiService.js"

import{
    LOGIN,
    VERIFY_EMAIL,
    VERIFY_OTP
} from "@/api/ApiRoutes"

    export const login = async (data) => {
        const response = await apiService.post(LOGIN,data)
     return response;
    };
    export const VerifyEmail = async (data) => {
        const response = await apiService.post(VERIFY_EMAIL,data)
     return response;
    };

    export const VerifyOTP = async (data) => {
        const response = await apiService.post(VERIFY_OTP,data)
     return response;
    };
