// import { createApp } from "vue";
import Vue from 'vue'
import App from './App.vue'
import router from './routes/router'
import PrimeVue from 'primevue/config';
import vuelidate from 'vuelidate';
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import TostService from 'primevue/toastservice';
import './assets/main.css'

Vue.config.productionTip = false

const requireComponent = require.context(
  // The relative path of the components folder
  // "./components/base",
  "./components",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
 
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});





// import "./assets/theme_assets/sass/style.scss";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons


Vue.use(PrimeVue);
Vue.use(TostService);
Vue.use(vuelidate);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


