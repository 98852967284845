<template>
  <div class="main-content" @submit.prevent="handleSubmit(!$v.$invalid)">
    <div class="signUP-admin">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-5 col-md-5 p-0">
            <div class="signUP-admin-left signIn-admin-left position-relative">
              <div class="signUP-overlay">
                <!-- <img class="svg signupTop" src="../../assets/img/svg/signupTop.svg" alt="img" />
                                <img class="svg signupBottom" src="../../assets/img/svg/signupBottom.svg" alt="img" /> -->
              </div>
              <!-- End: .signUP-overlay  -->
              <div class="signUP-admin-left__content">
                <div
                  class="
                    text-capitalize
                    mb-md-30 mb-15
                    d-flex
                    align-items-center
                    justify-content-md-start justify-content-center
                  "
                >
                  <a
                    class="
                      wh-36
                      bg-primary
                      text-white
                      radius-md
                      mr-10
                      content-center
                    "
                    href=""
                    >a</a
                  >
                  <span class="text-dark">admin</span>
                </div>
                <h1>Bootstrap 4 Vue Web Application</h1>
              </div>
              <!-- End: .signUP-admin-left__content  -->
              <div class="signUP-admin-left__img">
                <img
                  class="img-fluid svg"
                  src="../../assets/img/svg/signupIllustration.svg"
                  alt="img"
                  @error="replaceByDefault" 
                />
              </div>
              <!-- End: .signUP-admin-left__img  -->
            </div>
            <!-- End: .signUP-admin-left  -->
          </div>
          <!-- End: .col-xl-4  -->
          <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
            <div class="signUp-admin-right signIn-admin-right p-md-40 p-10">
              <div
                class="
                  signUp-topbar
                  d-flex
                  align-items-center
                  justify-content-md-end justify-content-center
                  mt-md-0
                  mb-md-0
                  mt-20
                  mb-1
                "
              >
                <p class="mb-0">
                  Don't have an account?
                  <a href="" class="color-primary">
                    <router-link to="/Registartionpage"> Sign up</router-link>
                  </a>
                </p>
              </div>
              <!-- End: .signUp-topbar  -->
              <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-8 col-md-12">
                  <div class="edit-profile mt-md-25 mt-0">
                    <div class="card border-0">
                      <div
                        class="
                          card-header
                          border-0
                          pb-md-15 pb-10
                          pt-md-20 pt-10
                        "
                      >
                        <div class="edit-profile__title">
                          <h6>
                            Sign Up To <span class="color-primary">Admin</span>
                          </h6>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="edit-profile__body">
                          <div class="form-group mb-20 p-fluid">
                            <label for="username"
                              >Username or Email Address</label
                            >
                            <BaseInput
                              type="text"
                              placeholder="Enter Your Email"
                              v-model="$v.email.$model"
                              :validate="$v.email"
                            />
                            <div
                              class="validation-div mb-2"
                              v-if="
                                typeof $v.email.required !== 'undefined' &&
                                $v.email.required == '' &&
                                $v.email.$error
                              "
                            >
                              Emailid is required
                            </div>
                            <div
                                  class="validation-div mb-2"
                                  v-if="!$v.email.email"
                                >
                                  Emailid should be in correct format
                                </div>
                          </div>
                          <div class="form-group mb-15 p-fluid">
                            <label for="password-field">password</label>
                            <div class="position-relative">
                              <Password
                                v-model="$v.password.$model"
                                :validate="$v.password"
                                :feedback="false"
                                toggleMask
                                placeholder="Enter your password"
                              />
                              <div
                                class="validation-div mb-2"
                                v-if="
                                  typeof $v.password.required !== 'undefined' &&
                                  $v.password.required == '' &&
                                  $v.password.$error
                                "
                              >
                                Password is required
                              </div>
                              <div
                                    class="validation-div mb-2"
                                    v-if="
                                      !$v.password.min 
                                    "
                                  >
                                  Password length must be atleast
                                    {{ $v.password.$params.min.min }}
                                    characters.
                                  </div>
                            </div>
                          </div>
                          <div
                            class="signUp-condition signIn-condition p-fluid"
                          >
                            <div class="checkbox-theme-default custom-checkbox">
                              <!-- <input class="checkbox" type="checkbox" id="check-1"> -->
                              <BaseCheckbox
                              type="checkbox"
                                id="Product"
                                class="Check"
                                name="Product"
                                value="BuyProduct"
                              >
                                <span
                                  style="display: flex"
                                  class="checkbox-text"
                                  >Keep me logged in &emsp;&emsp;&emsp;<span
                                    class="float-right"
                                    href=""
                                  >
                                    <router-link to="/Forgetpassword">
                                      forget password</router-link
                                    >
                                  </span></span
                                ></BaseCheckbox
                              >
                            </div>
                          </div>
                          &emsp;
                          <div
                            class="
                              button-group
                              d-flex
                              pt-1
                              justify-content-md-start justify-content-center
                            "
                          >
                            <BaseButton
                              color="info"
                              :loading="loading"
                              :disabled="loading || $v.$invalid"
                              @click="login()"
                              label="Sign In"
                              class="
                                p-button-raised p-button-info
                                btn btn-primary btn-default btn-squared
                                mr-15
                                text-capitalize
                                lh-normal
                                px-50
                                py-15
                                signIn-createBtn
                              "
                            ></BaseButton>
                          </div>
                          <p
                            class="
                              social-connector
                              text-center
                              mb-sm-25 mb-15
                              mt-sm-30 mt-20
                            "
                          >
                            <span>Or</span>
                          </p>
                          <div
                            class="
                              button-group
                              d-flex
                              align-items-center
                              justify-content-md-start justify-content-center
                            "
                          >
                            <ul class="signUp-socialBtn">
                              <button class="btn text-dark">
                                <img
                                  class="svg"
                                  src="../../assets/img/svg/google.svg"
                                  alt="img"
                                />
                                Sign up with Google
                              </button>
                              <button class="btn text-dark">
                                <img
                                  class="svg"
                                  src="../../assets/img/svg/facebook.svg"
                                  alt="img"
                                />
                              </button>
                              <button class="btn text-dark">
                                <img
                                  class="svg"
                                  src="../../assets/img/svg/twitter.svg"
                                  alt="img"
                                />
                              </button>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- End: .card-body -->
                    </div>
                    <!-- End: .card -->
                  </div>
                  <!-- End: .edit-profile -->
                </div>
                <!-- End: .col-xl-5 -->
              </div>
            </div>
            <!-- End: .signUp-admin-right  -->
          </div>
          <!-- End: .col-xl-8  -->
        </div>
      </div>
    </div>
    <!-- End: .signUP-admin  -->
    <Toast />
  </div>
</template>

<script>

import Password from "primevue/password";
import {
    login
} from "@/api/AuthApi";
import ResponseStatusEnum from "@/constants/enum/ResponseStatusEnum";
// import Button from "primevue/button";
import onImageError from "@/mixins/onImageError";
import {
    required,
    email,
    minLength
} from "vuelidate/lib/validators";

import Toast from 'primevue/toast';

export default {
    components: {
        Password,
        // Button,
        Toast
    },
    mixins: [onImageError],
    data() {
        return {
            email: null,
            art :[],
            password: null,
            loading: false,
            errorMessage: "",
        };
    },
    validations() {
        return {
            email: {
                required,
                email
            },
            password: {
                required,
                min: minLength(8),
            },
        };
    },
    methods: {
        removeMessage() {
            this.errorMessage = "";
        },
        handleSubmit(isFormValid) {
            this.$v.$touch();
            if (!isFormValid) {
                return;
            } else {
                this.login();
            }
        },
        login() {
            this.loading = true;
            const credentials = {
                email: this.email,
                password: this.password,
            };

            login(credentials)
                .then((response) => {
                    if (response.data.status == ResponseStatusEnum.SUCCESS) {
                        this.$router.push("/dashboardList");

                        this.$toastr.success(response.data.message);


                        // Set token
                        // this.$store.dispatch("user/authenticateUser", {
                        //     token: response.data.token,
                        // });

                        // set the user to state
                        this.$store.dispatch("user/setUser", response.data.result);
                    } else {
                        this.$toastr.error(response.data.message);
                        this.errorMessage = response ?.message;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
