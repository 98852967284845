<template>
<div>
    <headerPage/>
    <div style="display:flex">
        <SideBar />
        <slot />
    </div>
    <footerPage/>
</div>
</template>

<script>
import SideBar from '../layout/Sidebar/SideBar.vue';
import footerPage from "../layout/footerPage.vue";
import headerPage from "../layout/headerPage.vue"
export default {
    components: {
        SideBar,
        footerPage,
        headerPage
    }
}
</script>
